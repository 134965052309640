<template>
  <layout v-load="loading">

    <div class="container">
      <div class="card">
        <div class="card-body" v-if="token && !wrongToken && !isFilled">
          <h4 class="card-title">Анкета сотрудника</h4>
          <p
              class="card-title-desc font-italic"
          >Анкету необходимо заполнить на русском языке. В случае возникновения вопросов следует обратиться к HR менеджеру.</p>
          <div class="profile">
            <b-form
                @submit="handleFormSubmit"
            >
              <div class="row">
                <div class="col-lg-8">
                  <div class="row">
                    <b-form-group
                        label="Имя"
                        label-for="first-name"
                        class="col-lg"
                    >
                      <b-form-input
                          id="first-name"
                          v-model="form.firsName"
                          type="text"
                          placeholder="Введите имя"
                          required
                      />
                    </b-form-group>
                    <b-form-group
                        label="Фамилия"
                        label-for="last-name"
                        class="col-lg"
                    >
                      <b-form-input
                          id="last-name"
                          v-model="form.lastName"
                          type="text"
                          placeholder="Введите фамилию"
                          required
                      />
                    </b-form-group>
                    <b-form-group
                        label="Отчество"
                        label-for="patronymic"
                        class="col-lg"
                    >
                      <b-form-input
                          id="patronymic"
                          v-model="form.patronymic"
                          type="text"
                          placeholder="Введите отчество"
                          required
                      />
                    </b-form-group>
                  </div>
                  <div class="row">
                    <b-form-group
                        label="Дата рождения"
                        label-for="dob"
                        class="col-lg-4"
                    >
                      <date-picker
                          v-model="form.dob" type="date"
                          value-type="format"
                          format="YYYY-MM-DD"
                          :input-attr="{ required: 'required', id: 'dob'}"
                      />
                    </b-form-group>
                    <b-form-group
                        label="Место рождения"
                        label-for="born-place"
                        class="col-lg-4"
                    >
                      <b-form-input
                          id="born-place"
                          v-model="form.bornPlace"
                          type="text"
                          placeholder="Город, область, республика"
                          required
                      />
                    </b-form-group>
                    <b-form-group
                        label="ПИНФЛ"
                        label-for="born-place"
                        class="col-lg-4"
                    >
                      <b-form-input
                          id="born-place"
                          v-model="form.pinfl"
                          type="text"
                          placeholder="ПИНФЛ"
                          required
                      />
                    </b-form-group>
                  </div>
                </div>
                <div class="col-lg-4">
                  <AvatarUploader
                      v-if="profile"
                      :avatar="profile.photo"
                      @onChange="handleAvatarChange"
                  />
                </div>
              </div>
              <div class="row">
                <b-form-group
                    label="Адрес фактического места жительства"
                    label-for="address"
                    class="col-lg-8"
                >
                  <b-form-input
                      id="address"
                      v-model="form.address"
                      type="text"
                      placeholder="Город, улица, дом, квартира"
                      required
                  />
                </b-form-group>
                <b-form-group
                    label="Условия проживания"
                    label-for="сonditions"
                    class="col-lg-4"
                >
                  <b-form-select
                      id="сonditions"
                      v-model="form.accommodationСonditions"
                      :options="conditionOptions"
                      required
                  />
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                    label="1. Контактный телефон"
                    label-for="phone1"
                    class="col-lg"
                >
                  <b-form-input
                      id="phone1"
                      v-model="form.phone1"
                      type="text"
                      required
                  />
                </b-form-group>
                <b-form-group
                    label="2. Контактный телефон"
                    label-for="phone2"
                    class="col-lg"
                >
                  <b-form-input
                      id="phone2"
                      v-model="form.phone2"
                      type="text"
                      required
                  />
                </b-form-group>
                <b-form-group
                    label="Личный Email"
                    label-for="email"
                    class="col-lg"
                >
                  <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      required
                  />
                </b-form-group>
              </div>
              <div class="mb-3">
                2. Контактный телефон - Укажите один личный контактный телефон близкого человека (супруг, супруга, отец, мать, брат, сестра, сын, дочь, близкий друг), через которого можно с Вами связаться в экстренных случаях
              </div>
              <div class="row">
                <b-form-group
                    label="Гражданство"
                    label-for="citizenship"
                    class="col-lg-8"
                >
                  <b-form-input
                      id="citizenship"
                      v-model="form.citizenship"
                      type="text"
                      required
                  />
                </b-form-group>
                <b-form-group
                    label="Семейное положение"
                    label-for="maritalStatus"
                    class="col-lg-4"
                >
                  <b-form-select
                      id="maritalStatus"
                      v-model="form.maritalStatus"
                      :options="maritalStatusOptions"
                      required
                  />
                </b-form-group>
              </div>
              <Relatives
                  v-if="loaded"
                  :list="form.relatives"
                  @onChange="handleRelativesChange"
              />
              <Educations
                  v-if="loaded"
                  :data="form.educations"
                  @onChange="handleEducationChange"
              />
              <Works
                  v-if="loaded"
                  :list="form.works"
                  @onChange="handleWorksChange"
              />
              <SocialLinks
                  v-if="loaded"
                  :list="form.socialLinks"
                  @onChange="handleSocialLinksChange"
              />
              <div class="row">
                <b-form-group
                    label="Копия паспорта"
                    label-for="passport"
                    class="col-lg"
                >
                  <b-form-file
                      id="passport"
                      v-model="form.passport"
                      placeholder="Выберете файл"
                  />
                  <div class="mt-2">* Копия паспорта - главная страница с фото + разворот с пропиской</div>
                </b-form-group>
                <b-form-group
                    label="Копия заграничого паспорта (при наличии)"
                    label-for="foreign-passport"
                    class="col-lg"
                >
                  <b-form-file
                      id="foreign-passport"
                      v-model="form.fPassport"
                      placeholder="Выберете файл"
                  />
                  <div class="mt-2">* Копия заграничого паспорта - главная страница с фото</div>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-checkbox
                    v-model="form.data_responsibility"
                    value="1"
                    unchecked-value="0"
                    class="custom-checkbox mb-3"
                    checked
                >Беру на себя ответственность за достоверность данных</b-form-checkbox>
              </div>
              <div class="d-flex justify-content-end">
                <b-button
                    v-if="form.data_responsibility"
                    value="1"
                    unchecked-value="0"
                    type="submit"
                    variant="primary"
                >
                  Save
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
        <div v-else class="card-body">
          <h4 v-if="!token || wrongToken">Ссылка недействительна, обратитесь в HR отдел.</h4>
          <h4 v-if="isFilled" style="margin-bottom: 0;">Анкета успешно заполнена, спасибо!</h4>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import datePicker from 'vue2-datepicker';
import Works from './works.vue';
import Relatives from './relatives.vue';
import Educations from './educations.vue';
import SocialLinks from './social_links.vue';
import AvatarUploader from '@/components/AvatarUploader';
import { convertObjectToFormData } from '@/utils/converters';

export default {
  name: 'ProfileTab',
  components: {
    datePicker,
    Works,
    Relatives,
    Educations,
    SocialLinks,
    AvatarUploader,
  },
  props: ['employee'],
  data() {
    return {
      avatar: null,
      loaded: false,
      isFilled: false,
      wrongToken: false,
      conditionOptions: [
        { value: 'в квартире с родителями', text: 'в квартире с родителями' },
        { value: 'отдельная квартира', text: 'отдельная квартира' },
        { value: 'собственный дом', text: 'собственный дом' },
        { value: 'арендуемая квартира', text: 'арендуемая квартира' },
        { value: 'прочее', text: 'прочее' }
      ],
      maritalStatusOptions: [
        { value: 'не замужем / не женат', text: 'не замужем / не женат' },
        { value: 'разведен / разведена', text: 'разведен / разведена' },
        { value: 'замужем / женат', text: 'замужем / женат' },
        { value: 'состою в гражданском браке', text: 'состою в гражданском браке' },
      ],
      form: {
        firsName: '',
        lastName: '',
        patronymic: '',
        dob: null,
        bornPlace: '',
        pinfl: '',
        address: '',
        accommodationСonditions: null,
        phone1: '',
        phone2: '',
        email: '',
        citizenship: '',
        maritalStatus: null,
        relatives: [],
        data_responsibility: 0,
        educations: {
          secondary: [],
          secondaryProf: [],
          higher: [],
          extra: []
        },
        works: [],
        socialLinks: [],
        passport: null,
        fPassport: null
      }
    }
  },
  mounted() {
    this.$store.dispatch('offer/getProfile', this.token).then((res) => {
      if (res.data === null) {
        this.wrongToken = true;
      }
    });
  },
  computed: {
    profile() {
      return this.$store.state.offer.profile;
    },
    token() {
      return this.$route.query.token;
    },
    loading() {
      return this.$store.state.offer.loading;
    }
  },
  watch: {
    async profile(value) {
      if (value) {
        this.form.firsName = value.first_name;
        this.form.lastName = value.last_name;
        this.form.patronymic = value.middle_name;
        this.form.dob = value.birthday;
        this.form.bornPlace = value.birthplace;
        this.form.pinfl = value.pinfl;
        this.form.address = value.address;
        this.form.accommodationСonditions = value.living_condition;
        this.form.phone1 = value.phone;
        this.form.phone2 = value.secondary_contact_phone;
        this.form.email = value.personal_email;
        this.form.citizenship = value.citizenship;
        this.form.maritalStatus = value.marital_status;
        this.form.relatives = value.relatives.map((item) => ({
          type: item.type,
          name: item.name,
          birthday: item.birthday,
          work: item.work,
          position: item.position,
          address: item.address
        }));
        this.form.works = value.work_experiences.map((item) => ({
          dateStart: item.start_date,
          dateEnd: item.end_date,
          name: item.title,
          position: item.position
        }));
        this.form.socialLinks = value.social_networks.map(({ link }) => link);
        this.form.educations = value.educations.reduce((acc, item) => {
          const defaultFields = {
            type: item.type,
            dateStart: item.start_date,
            dateEnd: item.end_date,
            title: item.title
          }
          if (item.type === 'Среднее') {
            acc.secondary.push({
              ...defaultFields
            });
          }
          if (item.type === 'Среднее профессиональное') {
            acc.secondaryProf.push({
              ...defaultFields,
              department: item.department
            });
          }
          if (item.type === 'Высшее') {
            acc.higher.push({
              ...defaultFields,
              department: item.department,
              speciality: item.speciality
            });
          }
          if (item.type === 'Дополнительное') {
            acc.extra.push({
              ...defaultFields
            });
          }

          return acc;
        }, {
          secondary: [],
          secondaryProf: [],
          higher: [],
          extra: []
        });
        this.loaded = true;
      }
    }
  },
  methods: {
    handleAvatarChange(image) {
      this.avatar = image;
    },
    handleRelativesChange(relatives) {
      this.form.relatives = [ ...relatives ];
    },
    handleEducationChange(education) {
      this.form.educations = { ...education };
    },
    handleWorksChange(works) {
      this.form.works = [...works];
    },
    handleSocialLinksChange(socialLinks) {
      this.form.socialLinks = [...socialLinks];
    },
    async handleFormSubmit(event) {
      event.preventDefault();
      const fData = new FormData();
      fData.append('token', this.token);
      fData.append('last_name', this.form.lastName);
      fData.append('first_name', this.form.firsName);
      fData.append('middle_name', this.form.patronymic);
      fData.append('birthday', this.form.dob);
      fData.append('birthplace', this.form.bornPlace);
      fData.append('pinfl', this.form.pinfl);
      fData.append('address', this.form.address);
      fData.append('living_condition', this.form.accommodationСonditions);
      fData.append('phone', this.form.phone1);
      fData.append('secondary_contact_phone', this.form.phone2);
      fData.append('personal_email', this.form.email);
      fData.append('citizenship', this.form.citizenship);
      fData.append('marital_status', this.form.maritalStatus);
      fData.append('do_not_want_indicate_social_networks', this.form.socialLinks.length ? 1 : 0);
      fData.append('data_responsibility', this.form.data_responsibility);

      this.form.relatives.forEach((item, index) => {
        fData.append(`relatives[${index}][type]`, item.type);
        fData.append(`relatives[${index}][name]`, item.name);
        fData.append(`relatives[${index}][birthday]`, item.birthday);
        fData.append(`relatives[${index}][work]`, item.work);
        fData.append(`relatives[${index}][position]`, item.position);
        fData.append(`relatives[${index}][address]`, item.address);
      });

      this.form.works.forEach((item, index) => {
        fData.append(`work_experiences[${index}][title]`, item.name);
        fData.append(`work_experiences[${index}][end_date]`, item.dateEnd);
        fData.append(`work_experiences[${index}][position]`, item.position);
        fData.append(`work_experiences[${index}][start_date]`, item.dateStart);
      });

      this.form.socialLinks.forEach((item, index) => {
        fData.append(`social_networks[${index}][link]`, item);
      });

      let index = 0;
      [...this.form.educations.secondary, ...this.form.educations.extra].forEach((item) => {
        fData.append(`educations[${index}][end_date]`, item.dateEnd);
        fData.append(`educations[${index}][start_date]`, item.dateStart);
        fData.append(`educations[${index}][title]`, item.title);
        fData.append(`educations[${index}][type]`, item.type);
        index += 1;
      });

      [...this.form.educations.secondaryProf].forEach((item) => {
        fData.append(`educations[${index}][end_date]`, item.dateEnd);
        fData.append(`educations[${index}][start_date]`, item.dateStart);
        fData.append(`educations[${index}][title]`, item.title);
        fData.append(`educations[${index}][type]`, item.type);
        fData.append(`educations[${index}][department]`, item.department);
        index += 1;
      });

      [...this.form.educations.higher].forEach((item) => {
        fData.append(`educations[${index}][end_date]`, item.dateEnd);
        fData.append(`educations[${index}][start_date]`, item.dateStart);
        fData.append(`educations[${index}][title]`, item.title);
        fData.append(`educations[${index}][type]`, item.type);
        fData.append(`educations[${index}][department]`, item.department);
        fData.append(`educations[${index}][speciality]`, item.speciality);
        index += 1;
      });

      if (this.avatar) {
        await this.$store.dispatch('offer/uploadProfileDocument', {
          type: 'photo',
          reqData: convertObjectToFormData({
            document: this.avatar,
            name: this.avatar.name,
            token: this.token
          })
        });
      }

      if (this.form.passport) {
        await this.$store.dispatch('offer/uploadProfileDocument', {
          type: 'passport',
          reqData: convertObjectToFormData({
            document: this.form.passport,
            name: this.form.passport.name,
            token: this.token
          })
        });
      }

      if (this.form.fPassport) {
        await this.$store.dispatch('offer/uploadProfileDocument', {
          type: 'international_passport',
          reqData: convertObjectToFormData({
            document: this.form.fPassport,
            name: this.form.fPassport.name,
            token: this.token
          })
        });
      }

      await this.$store.dispatch('offer/updateProfile', {
        data: fData
      }).then(() => {
        this.isFilled = true;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    i {
      cursor: pointer;
    }
  }
</style>
